import _ from 'lodash';
import type { SeoTagsPayload } from '../types';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { type UIRouter, type Transition } from '@wix/tpa-router';

import { monitor } from './error-monitor';

/**
 * SEO helper tool
 * will call `resolver` with all resolvables for current route
 * so you can use them to generate SEO tags
 */
export function addSeoTagsProvider(
  router: UIRouter,
  params: ControllerParams,
  resolver: (
    transition: Transition,
  ) => Promise<SeoTagsPayload> | SeoTagsPayload,
) {
  return router.transitionService.onCreate({}, handleTransitionCreate);

  function handleTransitionCreate(transition: Transition) {
    const { controllerConfig } = params;
    const errorMonitor = monitor(params.flowAPI);

    transition.addResolvable(
      {
        token: 'SEO',
        deps: _.without(transition.getResolveTokens(), 'SEO'),
        async resolveFn() {
          try {
            const result = await resolver(transition);

            controllerConfig.wixCodeApi.seo.renderSEOTags(result);

            return result;
          } catch (error: any) {
            errorMonitor.captureException(error as Error, {
              transaction: 'SEO',
            });
            console.error(error);
          }
        },
      },
      transition.to(),
    );
  }
}
