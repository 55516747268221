import type { StateDeclaration, Transition } from '@wix/tpa-router';
import type { ControllerParams, TFunction } from '@wix/yoshi-flow-editor';
import type { SeoTagsPayload } from '../../../types';

import type { DraftContent } from '@wix/ricos';
import { ensureRicosContent, getText, getAbsoluteUrl } from '@wix/ricos';

import {
  extractContent,
  type IItemContent,
  safeJsonParse,
} from 'common/utils/ricos';
import type { GroupApp, GroupAppKey, IGroup } from 'store/groups/types';
import {
  selectFeed,
  selectFeedItem,
  selectFeedItems,
  selectGroupBySlug,
  selectInstalledApplications,
  selectMediaItems,
  selectMembers,
} from 'store/selectors';
import type { IRootStore } from 'store/index';

enum ESeoItemType {
  GROUPS_PAGE = 'GROUPS_PAGE',
  GROUPS_POST = 'GROUPS_POST',
}

export function seoTagsProvider(store: IRootStore, params: ControllerParams) {
  const { controllerConfig, flowAPI } = params;

  return function (transition: Transition) {
    const router = transition.router;
    const state = store.getState();

    const current = transition.to();
    const params = transition.params('to');

    const states = router.stateRegistry.get();
    const group = selectGroupBySlug(params.slug)(state);
    const applications = selectInstalledApplications(
      store.getState(),
      group.id as string,
    );

    const tags: SeoTagsPayload = {
      itemType: ESeoItemType.GROUPS_PAGE,
      itemData: {
        // tmp flag for easier SEO migration
        isMigrated: true,
        directUrl: controllerConfig.wixCodeApi.location.url,
        group: prepareGroup(group),
        tabs: prepareTabs(
          applications,
          states,
          flowAPI.translations.t as TFunction,
        ),
      },
    };

    switch (current.name) {
      case 'group.discussion.feed':
        tags.itemData = {
          ...tags.itemData,
          ...getFeedPageData(transition),
          activeTab: 'group.discussion.feed',
        };

        break;

      case 'group.discussion.post':
        tags.itemType = ESeoItemType.GROUPS_POST;
        tags.itemData = {
          ...tags.itemData,
          ...getPostPageData(transition),
          activeTab: 'group.discussion.feed',
        };
        break;

      case 'group.members':
        tags.itemData.groupMembers = selectMembers(state);
        tags.itemData.activeTab = 'group.members';
        break;

      case 'group.media':
        const { items } = selectMediaItems(state);

        tags.itemData.media = items;
        tags.itemData.activeTab = 'group.media';
        break;

      case 'group.events':
      case 'group.events.list':
      case 'group.events.edit':
      case 'group.events.create':
        tags.itemData.activeTab = 'group.events';
        break;

      case 'group.custom.tab':
        tags.itemData.activeTab = 'group.custom.tab';
        break;

      case 'group.files':
        tags.itemData.activeTab = 'group.files';
        break;

      case 'group.about':
      default:
        tags.itemData.activeTab = 'group.about';
        break;
    }

    return tags;
  };

  function getPostPageData(transition: Transition) {
    const params = transition.params('to');
    const state = store.getState();

    const post = selectFeedItem(state, params.feedItemId);

    const content = safeJsonParse<DraftContent>(
      post?.entity.body?.content as string,
    );
    const ricosContent = content ? ensureRicosContent(content) : content;

    return {
      post,
      postData: extractContent(ricosContent),
    };
  }

  function getFeedPageData(transition: Transition) {
    const router = transition.router;
    const params = transition.params('to');
    const state = store.getState();

    const feed = selectFeed(state);
    const feedItems = selectFeedItems(state);

    const currentUrl = router.stateService.href(
      'group.discussion.feed',
      params,
      { absolute: true },
    );

    const nextPageUrl = router.stateService.href(
      'group.discussion.feed',
      {
        slug: params.slug,
        page: feed.nextCursor,
      },
      {
        absolute: true,
        inherit: false,
      },
    );

    const prevPageUrl = router.stateService.href(
      'group.discussion.feed',
      {
        slug: params.slug,
        page: feed.prevCursor,
      },
      {
        absolute: true,
        inherit: false,
      },
    );

    const groupFeedUrl = router.stateService.href(
      'group.discussion.feed',
      { slug: params.slug },
      {
        absolute: true,
        inherit: false,
      },
    );

    const feedItemsData: { [feedItemId: string]: IItemContent } =
      Object.fromEntries(
        feedItems.map((item) => {
          const content = safeJsonParse<DraftContent>(
            item.entity.body?.content as string,
          );
          const ricosContent = content ? ensureRicosContent(content) : content;

          return [item.feedItemId, extractContent(ricosContent)];
        }),
      );

    return {
      feedItems,
      feedItemsData,
      feed: {
        currentUrl,
        url: groupFeedUrl,
        prevUrl: prevPageUrl,
        nextUrl: nextPageUrl,
      },
    };
  }
}

function prepareTabs(
  installed: GroupApp[],
  states: StateDeclaration[],
  t: TFunction,
) {
  return installed.reduce((acc, application) => {
    const route = getApplicationRoute(application.key as GroupAppKey);

    acc[route?.name as string] =
      application.customName || t(route?.data?.title);

    return acc;
  }, {} as { [state: string]: string });

  function getApplicationRoute(key: GroupAppKey) {
    return states.find((route) => route.data?.application === key);
  }
}

function prepareGroup(group: IGroup): IGroup {
  return {
    ...group,
    description: getDescription(),
    coverImage: getCoverImage(),
  };

  function getDescription() {
    const content = safeJsonParse<DraftContent>(group.description);
    if (!content) {
      return group.description ?? '';
    }

    const ricosContent = ensureRicosContent(content);
    return ricosContent ? getText(ricosContent).join(' ') : '';
  }

  function getCoverImage() {
    if (!group.coverImage?.image) {
      return group.coverImage;
    }

    const { mediaId, fileUrl } = group.coverImage.image;

    if (!mediaId && !fileUrl) {
      return group.coverImage;
    }

    return {
      ...group.coverImage,
      image: {
        ...group.coverImage.image,
        fileUrl: fileUrl || getAbsoluteUrl(mediaId as string, 'image'),
      },
    };
  }
}
