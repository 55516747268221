import type { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import { type IViewModel } from 'controller/types';

import { config404 } from './404';
import { activity } from './activity';
import { autoJoin } from './auto-join';
import { defaultTab } from './default-tab';
import { editorDefaults } from './editor-defaults';
import { groupSlug } from './group-slug';

export function config(
  router: UIRouter,
  params: ControllerParams,
  vm: IViewModel,
) {
  activity(router, vm, params);
  autoJoin(router, vm, params);
  defaultTab(router, params);
  editorDefaults(router, params);
  groupSlug(router);
  config404(router, params, {
    'group.**': ['group'],
    'group.discussion.post': ['group', 'feedItem'],
  });
}
