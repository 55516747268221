import { UIRouter, RejectType } from '@wix/tpa-router';
import { ControllerParams } from '@wix/yoshi-flow-editor';

export function config404(
  router: UIRouter,
  params: ControllerParams,
  states: Record<string, string[]>,
) {
  const { seo } = params.controllerConfig.wixCodeApi;

  for (const name in states) {
    const deps = states[name];

    router.transitionService.onError(
      { to: name },
      async function (transition) {
        const injector = transition.injector();
        const error = transition.error();

        if (error.type !== RejectType.ERROR) {
          return;
        }

        try {
          await Promise.all(deps.map((token) => injector.getAsync(token)));
        } catch {
          seo.setSeoStatusCode(404);
        }
      },
      { invokeLimit: 1, priority: 999 },
    );
  }
}
